






import Vue from 'vue';

export default Vue.extend({
  name: 'loading-spinner',
});
